import general from './general';
import { Filter } from './filter';

const complexes = {
    init: function () {
        document.addEventListener('DOMContentLoaded', () => {
            this.filter();
            // this.faq();
            this.cards();
            this.cardGalleryAnimate();

            this.updateCurrencyDisplay()
        });
    },
    filter: function () {
        if (!$('#filter').length) {
            return;
        }

        let filter = new Filter({
            filterBlockSelector: '#filter, #more-filters-popup',
            filterResultSelector: '#filter-result',
            filterFoundSelector: '#filter-found',
            showSelector: '.showIfComplexes',
            loadMoreBtnSelector: '#load-more',
            filterShowBtnSelector: '.show-results',
            toggleMapBtnSelector: '.show-map',
            filterMapResultSelector: '#filter-map',
            tagsBlockSelector: '.filter__under-row .filter__tags',
        });

        filter.isActiveFilterCallback = function (el) {
            if (el.parents('[data-price]').length) {
                if ($('[name="type-filter-price"]:checked').val() !== el.parents('[data-price]').data('price')) {
                    return false;
                }
            }

            if (!el.parents('#created-dynamic-filters').length) {
                if (el.data('checkbox-check-like-this') !== undefined) {
                    if (el.parents('#more-filters-popup').length) {
                        return el.parents('#more-filters-popup').is(':visible');
                    } else {
                        return $('#more-filters-popup').is(':hidden');
                    }
                } else {
                    return true;
                }
            } else {
                return true;
            }
        };

        filter.filterBlock.find('input:not([data-ignore-filter])').change(function () {
            filter.sendFilter();
        });

        $(document).on('locations-saved', function () {
            filter.sendFilter();
        })
    },

    cardsSliderInit: function() {
        $(document).on("init-card-slider", function() {
            intiFlickity();
        });

        intiFlickity();

        function intiFlickity() {
            $(".card__slider").each(function(){
                let slider = $(this);
                if(!slider.hasClass("flickity-enabled")){
                    slider.flickity({
                        prevNextButtons: false,
                        pageDots: true,
                        wrapAround: true,
                    });
                    
                    let nextButton = slider.siblings('.card__slider-buttons').find('.card__slider-button_next');
                    let prevButton = slider.siblings('.card__slider-buttons').find('.card__slider-button_prev');

                    if(nextButton.length && prevButton.length){
                        nextButton.on('click', function(){
                            slider.flickity('next');
                        });
                        prevButton.on('click', function(){
                            slider.flickity('previous');
                        });
                    }
                }
            });
        }
    },

    cards: function() {
        complexes.cardsSliderInit();
        function cardSetHeight() {
            if ($(window).width() > 767) {
                $(".card").each(function() {
                    var descHeight = $(this)
                            .find('[data-content="desc"]')
                            .outerHeight(),
                        neumsHeight = $(this)
                            .find('[data-content="nums"]')
                            .outerHeight();

                    if (typeof descHeight == "number" && typeof neumsHeight == "number") {
                        var maxHeight = Math.max(descHeight, neumsHeight);
                    }

                    $(this)
                        .find("[data-content]")
                        .css("height", maxHeight);
                });
            }
        }

        function stickersControl() {
            
            $('.card__images-tags').each(function () {
                var $this = $(this);
                if ($this.children().length === 0 && $(window).width() > 767) {
                    $this.siblings('.card__flags').css('display', 'flex');
                }
            });
        }

        cardSetHeight();
        stickersControl();

        $(document).on("updateFilters", function () {
            complexes.cardsSliderInit();
            cardSetHeight();
            stickersControl();
        })

        if ($(".card__title").length !== 0) {
            $(".card__title").each(function() {
                if ($(this).text().length > 22) {
                    $(this).addClass("small");
                }
            });
        }

        $(document).on("click", ".card__toggle button", function() {
            var card = $(this).closest(".card"),
                val = $(this).attr("data-value");

            card.find(".card__toggle button").removeClass("selected");
            $(this).addClass("selected");
            card.find(".card__inner").removeClass("show");
            card.find(`.card__inner[data-content=${val}]`).addClass("show");
        });

        $(document).on("click", ".card__nums button[name='currency']", function() {
            var block = $(this).closest(".card__nums");
            block.find('button[name="currency"]').removeClass("active");
            $(this).addClass("active");
            block.find(`[data-currency]`).removeClass("show");
            block.find(`[data-currency="${$(this).data("value")}"]`).addClass("show");
        });

        $(document).on("click", "[data-lot-render]", function(e) {
            var lotRender = $(this).data("lot-render");
            general.setCookie("lot_render", lotRender);
            toggleCardsView(lotRender);
        });

        toggleCardsView(general.getCookie("lot_render"));

        function toggleCardsView(toggle) {
            if (typeof toggle == "undefined") {
                $(`[data-lot-render="0"]`).addClass("active");
                return;
            }
            if (toggle == 1) {
                $(".cards").addClass("cards_grid");
            } else {
                $(".cards:not(.cards_row)").removeClass("cards_grid");
            }
            $(`[data-lot-render]`).removeClass("active");
            $(`[data-lot-render=${toggle}]`).addClass("active");
        }

        $(document).on('currencyChange', this.updateCurrencyDisplay)
    },

    updateCurrencyDisplay: function() {
        const selectedCurrency = sessionStorage.getItem('selectedCurrency') || 'rub'
        let priceItems, priceAreaItems, buttons, first, priceLists, currencies, on, localCurrency

        $('.card').each(function() {
            priceLists = $(this).find('.price-list')

            if (priceLists.length) {
                currencies = Array()

                priceLists.first().find('.price-item').each(function() {
                    currencies[currencies.length] = $(this).data('currency')
                })

                if (currencies.length) {
                    localCurrency = false

                    priceLists.each(function() {
                        priceItems = $(this).find('.price-item')

                        if (priceItems.length) {
                            on = false
                            priceItems.removeClass('show').each(function() {
                                if ($(this).data('currency') === selectedCurrency) {
                                    $(this).addClass('show')
                                    on = true
                                    return false
                                }
                            })

                            if (!on) {
                                first = priceItems.first()
                                first.addClass('show')

                                if (!localCurrency) {
                                    localCurrency = first.data('currency')
                                }
                            }
                        }
                    })

                    if (!localCurrency) {
                        localCurrency = selectedCurrency
                    }

                    //if (!$(this).hasClass('card_complex')) {
                        buttons = $(this).find('.card__currencies button')
                        if (buttons.length) {
                            buttons.removeClass('active').each(function() {
                                if ($(this).data('value') === localCurrency) {
                                    $(this).addClass('active')
                                    return false
                                }
                            })
                        }

                        priceAreaItems = $(this).find('.card__price-area .price-item')
                        if (priceAreaItems.length) {
                            priceAreaItems.removeClass('show').each(function() {
                                if ($(this).data('currency') === localCurrency) {
                                    $(this).addClass('show')
                                }
                            })
                        }

                        priceAreaItems = $(this).find('td .price-item')
                        if (priceAreaItems.length) {
                            priceAreaItems.removeClass('show').each(function() {
                                if ($(this).data('currency') === localCurrency) {
                                    $(this).addClass('show')
                                }
                            })
                        }
                    //}
                }
            }
            return;
        })
    },

    cardGalleryAnimate: function() {
        if ($(".form-gallery__decoration-inner").length === 0) return;

        window.onload = function() {
            $(".form-gallery").addClass("loaded");
        }

        var items = $(".form-gallery__decoration-inner").find(".form-gallery__item"),
            itemLength = items.length,
            activeItem = itemLength - 1,
            timeInterval = 5000;

        $(items[activeItem]).addClass("show");

        setInterval(function() {
            if (activeItem > 0) {
                activeItem--;
            } else {
                activeItem = itemLength - 1;
            }
            toggleGalleryCard(activeItem);
        }, timeInterval);

        function toggleGalleryCard(count) {
            var previous = count == itemLength - 1 ? 0 : count + 1;

            $(items[previous]).addClass("hidden");
            setTimeout(function() {
                $(items[previous]).removeClass("show");
                $(items[previous]).removeClass("hidden");
            }, timeInterval);
            $(items[count]).addClass("show");
        }
    }
}

general.init();
complexes.init();
export default complexes;
